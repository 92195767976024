(function(i, p, w, d, b, t){
    var TNT_Reveal_Ad = {
        'el': {
            'ad_wrapper': '#x-reveal-ad',
            'ad_link_id': '#x-reveal-ad-link',
            'ad_creative_id': '#x-reveal-ad-wrapper',
            'site_wrapper': '#site-container',
            'site_body': '#main-body-container'
        },
        'is_desktop': function() {
            return (w.innerWidth > 767) ? true : false;
        },
        'ad_height': function() {
            var selctorStr = (this.mobile_view()) ? '.x-reveal-height.mobile img' : '.x-reveal-height img';
            return d.querySelector(selctorStr).offsetHeight;
        },
        'mobile_view': function() {
            return (!this.is_desktop() && this.settings.mobile_slides[0][1]) ? true : false;
        },
        'num_images': function() {
            var count = 1,
                slideObj = (this.mobile_view()) ? this.settings.mobile_slides : this.settings.slides;
                
            if (slideObj[1][1]) count++;
            if (slideObj[2][1]) count++;
            return count;
        },
        'set_height': function() {
            var h = this.ad_height();
            d.querySelector(this.el.ad_wrapper).style.height = h + 'px';
            d.querySelector(this.el.site_wrapper).style.marginTop = (h - 1) + 'px';
        },
        'youtube_player': function() {
            var el = d.createElement('div');
            el.innerHTML = this.settings.youtube;

            var ytSrc = el.childNodes[0].getAttribute('src'),
                ytID = ytSrc.substring(ytSrc.indexOf("embed/") + 6);

            if (ytID.indexOf('?') > -1) ytID = ytID.substring(0, ytID.length - 1);
            ytSrc = ytSrc + ((ytSrc.indexOf('?') > -1) ? '&' : '?') + 'enablejsapi=1&autoplay=1&loop=1&playlist=' + ytID + '&modestbranding=1&controls=1';
            var player = '<iframe id="x-reveal-yt-frame" src="' + ytSrc + '" frameborder="0"></iframe>';
            return player;
        },
        'youtube_init': function() {  
            if (this.settings.youtube && this.is_desktop()) {
                w.onPlayerReady = function(e) {
                    TNT_Reveal_Ad.youtube_events(e);
                    TNT_Reveal_Ad.set_height();
                };
                w.onYouTubeIframeAPIReady = function() {
                    w.yt_player = new w.YT.Player('x-reveal-yt-frame', {
                        events: {
                            'onReady': w.onPlayerReady,
                        }
                    });
                };
                var tag = d.createElement('script');
                    tag.src = '//www.youtube.com/iframe_api';
                    tag.async = true;
                var firstScriptTag = d.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
        },
        'youtube_events': function(e) { 
            var paused = false,
                that = this,
                throttle2;

            e.target.mute();

            d.querySelector(this.el.ad_link_id).addEventListener('click', function() {
                e.target.pauseVideo();
            });

            w.addEventListener('scroll', function() {
                var imgHeight = that.ad_height(),
                    pausePoint = w.pageYOffset >= imgHeight;
                if (!paused && pausePoint) {
                    paused = true;
                    e.target.pauseVideo();
                } else if (paused && !pausePoint) {
                    paused = false;
                    e.target.playVideo();
                }
            });

            w.addEventListener('resize', function() {
                clearTimeout(throttle2);
                throttle2 = setTimeout(function() {
                    if (!that.is_desktop()) {
                        e.target.pauseVideo();
                    }
                }, 300);
            });
        },
        'ad_html': function() {
            var col = (this.settings.youtube && this.is_desktop()) ? 'col-md-8' : 'col-md-12';
            var mobile_col = this.settings.mobile_slide ? 'hidden-xs' : '';
            var html = '' +
            '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css">' +
            '<style>' +
                this.el.ad_wrapper + ' { z-index:0; position: fixed; overflow: hidden; background-color: #' + this.settings.bg_color.replace('#','') + '; top:0; width: 100%; }' +    
                this.el.ad_wrapper + ' .reveal-col { padding: 0; }' +
                this.el.ad_wrapper + ' .reveal-col img { width: 100%; }' +
                this.el.ad_wrapper + ' .reveal-col .animated { position: absolute; opacity:0; transition: opacity .7s ease-in-out; }' +
                this.el.ad_wrapper + ' .reveal-col-video { padding: 30px; }' +
                this.el.ad_wrapper + ' .reveal-col-video .video-responsive { box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5); }' +
                this.el.site_wrapper + ' { z-index:1; transition: margin-top .7s ease-in-out; }' +
                this.el.site_body+' { z-index:1; background-color: ' + w.getComputedStyle(b)['backgroundColor'] + '; }'+
                this.el.ad_creative_id + ' { position: relative; width: 100%; max-width: 1920px; margin: 0 auto; color: #fff; font-size: 50px;}';
                if (this.settings.bg_color_end) {
                    html += this.el.ad_wrapper + ' .gradient { position: absolute; width: 100%; height: 100%; background: radial-gradient(circle at 6.42% 89.08%, #' + this.settings.bg_color.replace('#','') + ', transparent 100%),radial-gradient(circle at 63.71% 35.88%, #' + this.settings.bg_color_end.replace('#','') + ', transparent 100%),radial-gradient(circle at 50% 50%, #000000, #000000 100%); }';
                }
                if (this.settings.youtube_controls === '1') {
                    html += this.el.ad_wrapper + ' .reveal-col-video iframe { z-index: 3; }';
                }
            html += '</style>' +
            '<div id="' + this.el.ad_wrapper.replace('#','') + '" class="hidden-print">' +
                '<div class="gradient"></div>' +
                '<div id="' + this.el.ad_creative_id.replace('#','') + '">' +
                    '<div class="container_fluid">' +
                        '<div class="row">' +
                            '<a id="' + this.el.ad_link_id.replace('#','') + '" href="' + this.settings.click_tag + '" target="_blank">' +
                                '<div id="x-reveal-slides" class="reveal-col ' + col + '">' +
                                    '<div class="' + mobile_col + ' x-reveal-height">' +
                                        '<div id="' + this.settings.slides[0][0].replace('#','') + '" class="desktop animated">' +
                                            '<img class="img-responsive" src="' + this.settings.slides[0][1] + '">' +
                                        '</div>' +
                                    '</div>';
                                    if (this.settings.slides[1][1]) {
                                        html += '<div class="' + mobile_col + '">' +
                                            '<div id="' + this.settings.slides[1][0].replace('#','') + '" class="desktop animated">' +
                                                '<img class="img-responsive" src="' + this.settings.slides[1][1] + '">' +
                                            '</div>' +
                                        '</div>';
                                    }
                                    if (this.settings.slides[2][1]) {
                                        html += '<div class="' + mobile_col + '">' +
                                            '<div id="' + this.settings.slides[2][0].replace('#','') + '" class="desktop animated">' +
                                                '<img class="img-responsive" src="' + this.settings.slides[2][1] + '">' +
                                            '</div>' +
                                        '</div>';
                                    }
                                    if (this.settings.mobile_slides[0][1]) {
                                        html += '<div class="visible-xs x-reveal-height mobile">' +
                                            '<div id="' + this.settings.mobile_slides[0][0].replace('#','') + '" class="mobile animated">' +
                                                '<img class="img-responsive" src="' + this.settings.mobile_slides[0][1] + '">' +
                                            '</div>' +
                                        '</div>';
                                    }
                                    if (this.settings.mobile_slides[1][1]) {
                                        html += '<div class="visible-xs">' +
                                            '<div id="' + this.settings.mobile_slides[1][0].replace('#','') + '" class="mobile animated">' +
                                                '<img class="img-responsive" src="' + this.settings.mobile_slides[1][1] + '">' +
                                            '</div>' +
                                        '</div>';
                                    }
                                    if (this.settings.mobile_slides[2][1]) {
                                        html += '<div class="visible-xs">' +
                                            '<div id="' + this.settings.mobile_slides[2][0].replace('#','') + '" class="mobile animated">' +
                                                '<img class="img-responsive" src="' + this.settings.mobile_slides[2][1] + '">' +
                                            '</div>' +
                                        '</div>';
                                    }
                                    if (this.settings.tracking_pixel) {
                                        html += '<div class="x-reveal-tracking" style="display:none;">' +
                                            '<img src="' + this.settings.tracking_pixel + '" height="1" width="1">' +
                                        '</div>';
                                    }
                                html += '</div>' +
                            '</a>';
                            if (this.settings.youtube && this.is_desktop()) {
                                html += '<div class="reveal-col reveal-col-video col-md-4 hidden-sm hidden-xs">' +
                                    '<div class="video-responsive">' + this.youtube_player() + '</div>' +
                                '</div>';
                            }
                        html += '</div>' +
                    '</div>' +
                '</div>' +
            '</div>';
            return html;
        },
        'animate_slides': function() {
            var viewport = (this.mobile_view()) ? 'mobile' : 'desktop',
                slideObj = (viewport === 'desktop') ? this.settings.slides : this.settings.mobile_slides,
                img_count = this.num_images();

            if (img_count === 1) {
                var firstSlide = (viewport === 'desktop') ? d.querySelector('.x-reveal-height div') : d.querySelector('.x-reveal-height.mobile div');

                firstSlide.classList.remove('animated');
                firstSlide.style.opacity = 1;
                return false;
            }

            var slides = d.querySelectorAll(this.el.ad_wrapper + ' .' + viewport + '.animated'),
                timer = parseInt(this.settings.timer),
                delay = 300,
                that = this;
            
            for (i = 0; i < slides.length; i++) {
                slides[i].className = viewport +' animated';
            }
            setTimeout(function() {
                d.querySelector(slideObj[0][0]).classList.add('fadeIn' + slideObj[0][2]);
                if (slideObj[1][1]) {
                    setTimeout(function() {
                        d.querySelector(slideObj[0][0]).classList.add('fadeOut' + slideObj[1][2]);
                        setTimeout(function() {
                            d.querySelector(slideObj[1][0]).classList.add('fadeIn' + slideObj[1][2]);
                        }, delay);
                        if (slideObj[2][1]) {
                            setTimeout(function() {
                                d.querySelector(slideObj[1][0]).classList.add('fadeOut' + slideObj[2][2]);
                                setTimeout(function() {
                                    d.querySelector(slideObj[2][0]).classList.add('fadeIn' + slideObj[2][2]);
                                }, delay);
                            }, timer);
                        }
                    }, timer);
                }
            }, (delay + delay));

            if (this.settings.loop === 'true') {
                var loop = ((timer * img_count) + (delay * img_count) + timer);
                setTimeout(function() {
                    d.querySelector(slideObj[(img_count - 1)][0]).classList.add('fadeOut');
                    setTimeout(function() {
                        that.animate_slides();
                    }, (delay + delay));
                }, loop);
            }
        },
        'load': function(settings) {
            this.settings = settings;
            this.youtube_init();

            var siteContainer = d.querySelector(this.el.site_wrapper),
                that = this,
                throttle;

            if (p) {
                p.style.display = 'none';
                p.parentNode.style.display = 'none';
            }

            siteContainer.insertAdjacentHTML('beforebegin', this.ad_html());

            var img = d.querySelector(this.settings.slides[0][0] + ' img');
            img.addEventListener('load', function() {
                setTimeout(function() {
                    that.set_height();
                }, 150);
                that.animate_slides();
            });

            w.addEventListener('resize', function() {
                clearTimeout(throttle);
                throttle = setTimeout(function() {
                    that.set_height();
                }, 500);
            });
        },
    };

    try {
        if (!i) {
            throw 'Friendly iframe required.';
        }
        Array.prototype.forEach.call(t.cmds, function(el) {
            TNT_Reveal_Ad.load(el.call());
            return false;
        });
    }
    catch(e){
        if (w.console) {
            w.console.warn(e);
        }
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.__TNT_AD || {})
);